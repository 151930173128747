//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ErrorLayout from '@/middleware/error-layout'

import '@/assets/css/main.scss'

export default {
  layout: ErrorLayout,
  beforeCreate() {
    require(`@/assets/css/${this.$config.org}.scss`)
  },
  methods: {
    getHomePageRoute() {
      const userType = this.$auth?.user?.data.type

      switch (userType) {
        case 'admin':
        case 'teacher':
        case 'assistant':
        case 'supervisor':
        case 'accountant':
          return '/dashboard/courses'
        default:
          return '/'
      }
    },
  },
}
