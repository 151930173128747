export default function ({ $auth }) {
  if (window.$zoho?.salesiq?.visitor && $auth.loggedIn) {
    const { id, name, phone_number } = $auth.user.data;

    window.$zoho.salesiq.visitor.id(id.toString());
    window.$zoho.salesiq.visitor.name(name);
    window.$zoho.salesiq.visitor.contactnumber(phone_number);

    console.log('zoho set.')
  }
}
