export const defaultState = () => ({
  latestTableOptions: {},
})

export const state = { cancelToken: null, ...defaultState() }

const notificationsPlugin = (store) => {
  // called when the store is initialized
  store.subscribe((mutation) => {
    // called after every mutation.
    if (mutation.type === 'auth/SET' && mutation.payload.key === 'loggedIn') {
      if (mutation.payload.value === true) {
        store.dispatch('serverNotifications/setUnReadNotifications', { per_page: 50, page: 1 })
        store.$initPushNotifications()
        const { phone_number, id, name } = store.$auth.user.data
        store.$sentry.setUser({ phone_number, name, id, ip_address: '{{auto}}' })
      } else {
        store.dispatch('authentication/resetAuthenticationState')
        store.dispatch('courses/resetCoursesState')
        store.dispatch('notifications/resetNotificationsState')
      }
    }
  })
}

export const plugins = [notificationsPlugin]

export const mutations = {
  SET_CANCEL_TOKEN(state, cancelToken) {
    state.cancelToken = cancelToken
  },
  SET_LATEST_TABLE_OPTIONS(state, { tableId, tableOptions }) {
    state.latestTableOptions[tableId] = JSON.stringify(tableOptions)
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const getters = {
  isTableOptionsChanged: (state) => ({ tableId, tableOptions }) => {
    return state.latestTableOptions[tableId] !== JSON.stringify(tableOptions)
  },
}

export const actions = {
  setCancelToken({ commit }, cancelToken) {
    commit('SET_CANCEL_TOKEN', cancelToken)
  },
  RESET_ALL({ commit }) {
    commit('RESET')
    commit('assessments/RESET')
    commit('courses/RESET')
    commit('notifications/RESET')
    commit('refs/RESET')
    commit('serverNotifications/RESET')
    commit('signup/RESET')
    commit('student/RESET')
    commit('users/RESET')
  },
}
