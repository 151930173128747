export default ({ $axios, $config }, inject) => {
  // UNSUBSCRIBE PUSH NOTIFICATIONS
  inject('unSubPushNotifications', () => {
    if (typeof navigator.serviceWorker === "undefined" || !navigator.serviceWorker.ready) {
      return
    }
    navigator.serviceWorker.ready
      .then((reg) => reg.pushManager.getSubscription())
      .then((sub) => {
        sub?.unsubscribe()
        return sub?.endpoint
      })
      .then(
        (endpoint) => {
          endpoint && $axios.$delete(`/api/v1/push-subscriptions/unsubscribe`, { data: { endpoint } })
          console.log('sent unsubscribe')
        }
      )
      .catch((err) => console.log('unsub err', err))
  })

  // PUSH NOTIFICATIONS INITIALIZATION
  inject('initPushNotifications', () => {
    const urlBase64ToUint8Array = (base64String) => {
      var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
      var rawData = window.atob(base64)
      var outputArray = new Uint8Array(rawData.length)
      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    }

    const storePushSubscription = (pushSubscription) => {
      $axios
        .$post(`/api/v1/push-subscriptions/subscribe`, {
          ...JSON.parse(JSON.stringify(pushSubscription)),
        })
        .then((res) => console.log('store response', res.data))
        .catch((err) => console.log('store err', err))
    }
    const subscribeUser = () => {
      if (typeof navigator.serviceWorker === "undefined") {
        return;
      }

      navigator.serviceWorker.ready
        .then((registration) => {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array($config.vapidPublicKey),
          }
          return registration.pushManager.subscribe(subscribeOptions)
        })
        .then((pushSubscription) => {
          storePushSubscription(pushSubscription)
        })
    }

    const initPush = () => {
      if (typeof navigator.serviceWorker === "undefined" || !navigator.serviceWorker.ready) {
        return
      }
      new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
          resolve(result)
        })
        if (permissionResult) {
          permissionResult.then(resolve, reject)
        }
      }).then((permissionResult) => {
        if (permissionResult !== 'granted') {
          console.log("Notification permission not granted.")
          return;
        }
        subscribeUser()
      })
    }

    initPush()
  })

  inject('closeNotificationsSocket', ($auth, $echo) => {
    const channelName = `App.User.${$auth.user.data.id}`


    console.log('leaving...')

    $echo.leave(channelName)

    console.log('left...')
  })
}
