export const defaultState = () => ({
  spinner: null,
})
export const state = defaultState()

export const mutations = {
  SET_SPINNER_REF(state, spinnerRef) {
    state.spinner = spinnerRef
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  setSpinnerRef({ commit }, spinnerRef) {
    commit('SET_SPINNER_REF', spinnerRef)
  },
}
