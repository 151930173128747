export default function ({ route, $config }) {
  if ($config.org === 'mansety') {
    return 'mansety'
  }

  const params = route.fullPath.split('/') // split route to get parent page (index 1)
  switch (params[1]) {
    case 'dashboard':
      return 'default'
    case 'home':
      return 'student'
    case 'login':
      return 'landing'
    default:
      return 'landing'
  }
}
