import { notification } from '~/utils'

export default ({ $axios, app, store, redirect, route }) => {
  store.dispatch('setCancelToken', $axios.CancelToken.source())

  $axios.onRequest((config) => {
    config.cancelToken = store.state.cancelToken.token
    store.dispatch('notifications/isLoading', true, { root: true })
  })
  $axios.onResponse((res) => {
    if (res?.status === 204) {
      store.dispatch('notifications/isLoading', false, { root: true })
    }
    store.dispatch('notifications/isLoading', false, { root: true })
  })

  $axios.onError(async (error) => {
    if (error.response?.status === 401) {
      await app.$auth.logout()
    }
    if (error.response?.status === 401 || error.response?.status === 419) {
      store.dispatch('notifications/isLoading', false, { root: true })

      if (route.path.indexOf('signUp') === -1) {
        store.commit('notifications/UNAUTHENTICATED', null, { root: true })
      }

      store.commit('authentication/RESET_AUTHENTICATION_STATE')
      store.dispatch('courses/resetCoursesState')
      store.dispatch('notifications/resetNotificationsState')

      if (route.fullPath !== '/login') {
        await app.$store.dispatch('RESET_ALL')
        await redirect('/login')
      }
    } else {
      store.dispatch('notifications/isLoading', false, { root: true })
    }

    throw error;
  })
}
