export default function ({ $auth }) {
  if (window.hj && $auth.loggedIn) {
    const { id, name, phone_number, source, type, email, phone_verified } = $auth.user.data;
    window.hj('identify', id, {
      name,
      phone_number,
      source,
      type,
      email,
      phone_verified,
    });

    console.log('hotjar set.')
  }
}
