import { notification } from '~/utils'

export const defaultState = () => ({
  courses: [],
  pendingCourses: [],
  currentCourse: {
    info: null,
    currentLesson: {
      info: null,
      video: null,
      videoPlayback: null,
      videoPlaybackOTP: null,
      videos: [],
      assessments: [],
      currentAssessment: {
        info: null,
        session: null,
        questions: [],
        comments: [],
      },
    },
    lessons: [],
    homeworks: [],
    quizzes: [],
    questionsTemplates: [],
  },
})

export const state = defaultState();

export const mutations = {
  RESET_COURSES_STATE(state) {
    state.courses = []
    state.pendingCourses = []
  },
  RESET_CURRENT_COURSE(state) {
    state.currentCourse = {
      info: null,
      currentLesson: {
        info: null,
        video: null,
        videoPlayback: null,
        videoPlaybackOTP: null,
        videos: [],
        assessments: [],
        currentAssessment: {
          info: null,
          session: null,
          questions: [],
          comments: [],
        },
      },
      lessons: [],
      homeworks: [],
      quizzes: [],
      questionsTemplates: [],
    }
  },
  RESET_CURRENT_LESSON(state) {
    state.currentCourse.currentLesson = {
      info: null,
      video: null,
      videoPlayback: null,
      videoPlaybackOTP: null,
      videos: [],
      assessments: [],
      currentAssessment: {
        info: null,
        session: null,
        questions: [],
        comments: [],
      },
    }
  },
  RESET_CURRENT_ASSESSMENT(state) {
    state.currentCourse.currentLesson.currentAssessment = {
      info: null,
      session: null,
      questions: [],
      comments: [],
    }
  },
  SET_COURSES(state, courses) {
    state.courses = courses
  },
  SET_PENDING_COURSES(state, courses) {
    state.pendingCourses = courses
  },
  SET_CURRENT_COURSE(state, currentCourse) {
    state.currentCourse = currentCourse
  },
  SET_CURRENT_LESSON(state, currentLesson) {
    state.currentCourse.currentLesson.info = currentLesson
  },
  SET_CURRENT_LESSON_ASSESSMENTS(state, assessments) {
    state.currentCourse.currentLesson.assessments = assessments
  },
  SET_CURRENT_LESSON_ASSESSMENT_SESSION(state, session) {
    state.currentCourse.currentLesson.currentAssessment.session = session
  },
  SET_CURRENT_LESSON_ASSESSMENT_INFO(state, info) {
    state.currentCourse.currentLesson.currentAssessment.info = info
  },
  SET_CURRENT_LESSON_ASSESSMENT_QUESTIONS(state, questions) {
    state.currentCourse.currentLesson.currentAssessment.questions = questions
  },
  SET_CURRENT_LESSON_VIDEOS(state, videos) {
    state.currentCourse.currentLesson.videos = videos
  },
  SET_CURRENT_LESSON_VIDEO(state, video) {
    state.currentCourse.currentLesson.video = video
  },
  SET_CURRENT_LESSON_VIDEO_PLAYBACK(state, videoPlayback) {
    state.currentCourse.currentLesson.videoPlayback = videoPlayback
  },
  SET_CURRENT_LESSON_VIDEO_PLAYBACK_OTP(state, videoPlaybackOTP) {
    state.currentCourse.currentLesson.videoPlaybackOTP = videoPlaybackOTP
  },
  SET_COURSE_LESSONS(state, lessons) {
    state.currentCourse.lessons = lessons
  },

  SET_COURSE_HOMEWORKS(state, homeworks) {
    state.currentCourse.homeworks = homeworks
  },
  SET_COURSE_QUIZZES(state, quizzes) {
    state.currentCourse.quizzes = quizzes
  },
  SET_COURSE_QUESTIONS_TEMPLATES(state, questionsTemplates) {
    state.currentCourse.questionsTemplates = questionsTemplates
  },
  SET_CURRENT_LESSON_ASSESSMENT_COMMENTS(state, comments) {
    state.currentCourse.currentLesson.currentAssessment.comments = comments
  },
  PUSH_COMMENT(state, comment) {
    state.currentCourse.currentLesson.currentAssessment.comments = state.currentCourse.currentLesson.currentAssessment.comments.concat(
      [comment]
    )
  },
  UPDATE_ASSESSMENT_ANSWER(state, { answer, questionId }) {
    const questions = state.currentCourse.currentLesson.currentAssessment.questions

    const questionIndex = questions.findIndex((question) => question.id === questionId)

    if (questionIndex === -1) {
      return
    }

    questions[questionIndex].answer = answer

    state.currentCourse.currentLesson.currentAssessment.questions = questions
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  resetCoursesState({ commit }) {
    commit('RESET_COURSES_STATE')
  },
  resetStudentCourse({ commit }) {
    commit('RESET_CURRENT_COURSE')
  },
  resetStudentLesson({ commit }) {
    commit('RESET_CURRENT_LESSON')
  },
  resetCurrentAssessment({ commit }) {
    commit('RESET_CURRENT_ASSESSMENT')
  },
  async fetchCourses({ commit, dispatch }) {
    try {
      const res = await this.$axios.$get('/api/v1/courses', { params: { status: 'accepted' } })
      commit('SET_COURSES', res.data)

      const pendingResp = await this.$axios.$get('/api/v1/courses?status=pending')
      commit('SET_PENDING_COURSES', pendingResp.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      } else {
        console.log('fetch course catch else', error)
        throw error
      }
    }
  },
  setCurrentCourse({ commit }, currentCourse) {
    commit('SET_CURRENT_COURSE', currentCourse)
  },
  async fetchCourseLessons({ commit, dispatch }, courseId) {
    try {
      const res = await this.$axios.$get(`/api/v1/courses/${courseId}/lessons`, { params: { per_page: -1 } })
      commit('SET_COURSE_LESSONS', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourseLesson({ commit, dispatch }, { courseId, lessonId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/courses/${courseId}/lessons/${lessonId}`, {
        params: {include: "resources" },
      })
      commit('SET_CURRENT_LESSON', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchLessonAssessments({ commit, dispatch }, lessonId) {
    try {
      const res = await this.$axios.$get(`/api/v1/lessons/${lessonId}/assessments`)
      commit('SET_CURRENT_LESSON_ASSESSMENTS', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchLessonAssessment({ commit, dispatch }, { lessonId, assessmentId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/lessons/${lessonId}/assessments/${assessmentId}`)
      commit('SET_CURRENT_LESSON_ASSESSMENT', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async openLessonAssessment({ commit, dispatch, state }, { lessonId, assessmentId }) {
    try {
      const res = await this.$axios.$post(
        `/api/v1/lessons/${lessonId}/assessments/${assessmentId}/open`
      )
      commit('SET_CURRENT_LESSON_ASSESSMENT_SESSION', res.data)
      const assessment = state.currentCourse.currentLesson.currentAssessment
      if (assessment?.session?.is_ended) {
        return
      } else {
        const res = await this.$axios.$get(
          `/api/v1/lessons/${lessonId}/assessments/${assessmentId}`
        )
        commit('SET_CURRENT_LESSON_ASSESSMENT_INFO', res.data)
      }
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) { return; }

      if (error?.response?.status !== 403) {
        dispatch(
          'notifications/add',
          notification('error', this.$i18n.t('assessments.assessment_session_error')),
          {
            root: true,
          }
        )
      }

      const assessment = await this.$axios.$get(
        `/api/v1/lessons/${lessonId}/assessments/${assessmentId}`
      )
      commit('SET_CURRENT_LESSON_ASSESSMENT_INFO', {
        ...assessment.data,
        message: 'غير مصرح',
      })
    }
  },
  async closeAssessmentSession({ commit, dispatch, state }, { lessonId, assessmentId }) {
    try {
      const res = await this.$axios.$put(
        `/api/v1/lessons/${lessonId}/assessments/${assessmentId}/submit`
      )
      commit('SET_CURRENT_LESSON_ASSESSMENT_SESSION', res.data)
      const assessment = state.currentCourse.currentLesson.currentAssessment
      if (assessment?.session?.is_ended) {
        return
      } else {
        const res = await this.$axios.$get(
          `/api/v1/lessons/${lessonId}/assessments/${assessmentId}`
        )
        commit('SET_CURRENT_LESSON_ASSESSMENT_INFO', res.data)
      }
    } catch (error) {
      if (this.$axios.isCancel(error) || error?.response?.status === 401) { return; }
      if (error?.response?.status !== 403) {
        dispatch(
          'notifications/add',
          notification('error', this.$i18n.t('assessments.assessment_session_error')),
          {
            root: true,
          }
        )
      }

      const assessment = await this.$axios.$get(
        `/api/v1/lessons/${lessonId}/assessments/${assessmentId}`
      )
      commit('SET_CURRENT_LESSON_ASSESSMENT_INFO', {
        ...assessment.data,
        message: 'غير مصرح',
      })
    }
  },
  async fetchAssessmentQuestions({ commit, dispatch }, { lessonId, assessmentId }) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/lessons/${lessonId}/assessments/${assessmentId}/questions?include=answer`
      )
      commit('SET_CURRENT_LESSON_ASSESSMENT_QUESTIONS', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && ![401, 403].includes(error?.response?.status) ) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchLessonVideos({ commit, dispatch }, lessonId) {
    try {
      const res = await this.$axios.$get(`/api/v1/lessons/${lessonId}/videos?include=prerequisiteAssessment`)
      commit(
        'SET_CURRENT_LESSON_VIDEOS',
        res.data.map((video) => ({
          ...video,
          playback: {
            allowedViews: null,
            remainingViews: null,
            views: null,
          },
        }))
      )
      const videosPlaybackInfo = await Promise.all(
        res.data.map((video) =>
          this.$axios.$get(`/api/v1/lessons/${lessonId}/videos/${video.id}/playback`)
        )
      )
      commit(
        'SET_CURRENT_LESSON_VIDEOS',
        res.data.map((video) => ({
          ...video,
          playback: videosPlaybackInfo.find((vid) => vid.data.video_id === video.id).data,
        }))
      )
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchLessonVideo({ commit, dispatch }, { lessonId, videoId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/lessons/${lessonId}/videos/${videoId}`)
      commit('SET_CURRENT_LESSON_VIDEO', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchLessonVideoPlayback({ commit, dispatch }, { lessonId, videoId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/lessons/${lessonId}/videos/${videoId}/playback`)
      commit('SET_CURRENT_LESSON_VIDEO_PLAYBACK', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && ![401, 403].includes(error?.response?.status) ) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourseQuestionsTemplates({ commit, dispatch }, courseId) {
    try {
      const res = await this.$axios.$get(`/api/v1/courses/${courseId}/questionTemplates`)
      commit('SET_COURSE_QUESTIONS_TEMPLATES', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourseHomeworks({ commit, dispatch }, courseId) {
    try {
      const res = await this.$axios.$get(`/api/v1/courses/${courseId}/assessments?type=homework`)
      commit('SET_COURSE_HOMEWORKS', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchCourseQuizzes({ commit, dispatch }, courseId) {
    try {
      const res = await this.$axios.$get(`/api/v1/courses/${courseId}/assessments?type=quiz`)
      commit('SET_COURSE_QUIZZES', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },

  async sendAssessmentAnswer(
    { dispatch, commit },
    { lessonId, assessmentId, question: { id: questionId, answerPayload } }
  ) {
    try {
      const res = await this.$axios.post(
        `/api/v1/lessons/${lessonId}/assessments/${assessmentId}/questions/${questionId}/answer`,
        answerPayload
      )
      dispatch('notifications/add', notification('success', this.$i18n.t('questions.answered')), {
        root: true,
      })

      commit('UPDATE_ASSESSMENT_ANSWER', {
        questionId,
        answer: res.data,
      })
    } catch (e) {
      dispatch('notifications/add', notification('error', e.response), {
        root: true,
      })
    }
  },
  async fetchAssessmentComments({ commit, dispatch }, { assessmentId }) {
    try {
      const res = await this.$axios.$get(`/api/v1/assessments/${assessmentId}/comments`)
      commit('SET_CURRENT_LESSON_ASSESSMENT_COMMENTS', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && ![401, 403].includes(error?.response?.status) ) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  newLiveComment({ commit }, comment) {
    commit('PUSH_COMMENT', comment)
  },
  async postCommentonAssessment({ dispatch }, { assessmentId, comment }) {
    try {
      const formData = new FormData()
      formData.append('body', comment.body)
      if (comment.image) {
        formData.append('image', comment.image)
      }
      if (comment.pdf) {
        formData.append('pdf', comment.image)
      }
      let headers = {'Content-Type': 'multipart/form-data'}
      if (this.$echo.socketId()) {
        headers['X-Socket-ID'] = this.$echo.socketId()
      }
      await this.$axios.$post(`/api/v1/assessments/${assessmentId}/comments`, formData, {
        headers,
      })
      dispatch('fetchAssessmentComments', { assessmentId })
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  }
}
