import { notification } from '~/utils'

const defaultState = () => ({
  sessions: [],
  totalSessions: 0,
  currentSession: null,
  prerequisiteAssessments: [],
  currentAssessment: {
    comments: [],
  },
})
export const state = defaultState()

export const mutations = {
  RESET_SESSIONS(state) {
    state.sessions = []
    state.totalSessions = 0
  },
  RESET_CURRENT_SESSION(state, session) {
    state.currentSession = null
  },
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions
  },
  SET_TOTAL_SESSIONS(state, totalSessions) {
    state.totalSessions = totalSessions
  },
  SET_CURRENT_SESSION(state, session) {
    state.currentSession = session
  },
  SET_CURRENT_LESSON_ASSESSMENT_COMMENTS(state, comments) {
    state.currentAssessment.comments = comments
  },
  SET_PREREQUISITE_ASSESSMENT(state, assessments) {
    state.prerequisiteAssessments = assessments
  },
  PUSH_COMMENT(state, comment) {
    state.currentAssessment.comments = state.currentAssessment.comments.concat([comment])
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  resetSessions({ commit }) {
    commit('RESET_SESSIONS')
  },
  resetCurrentSession({ commit }) {
    commit('RESET_CURRENT_SESSION')
  },
  async fetchAssessmentSessions(
    { commit, dispatch },
    { assessmentId, per_page, current_page, q, page, gradingStatus }
  ) {
    try {
      const params = {
        page: current_page || page,
        per_page,
        grading_status: gradingStatus,
      }
      if (q) {
        params.q = q
      }
      const res = await this.$axios.$get(
        `/api/v1/dashboard/assessments/${assessmentId}/sessions?include=student`,
        {
          params,
        }
      )
      commit('SET_SESSIONS', res.data)
      commit('SET_TOTAL_SESSIONS', res.meta.pagination.total)
    } catch (err) {
      if (err?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', err), {
        root: true,
      })
    }
  },
  async fetchAssessmentSession({ commit, dispatch }, { assessmentId, sessionId }) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/dashboard/assessments/${assessmentId}/sessions/${sessionId}?include=answers.answers,student`
      )
      commit('SET_CURRENT_SESSION', res.data)
    } catch (err) {
      if (err?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', err), {
        root: true,
      })
    }
  },
  async postAnswerGrade({ dispatch }, { assessmentId, sessionId, answerId, grade, feedback }) {
    try {
      await this.$axios.post(
        `/api/v1/dashboard/assessments/${assessmentId}/sessions/${sessionId}/grade`,
        {
          answer_id: answerId,
          grade,
          feedback
        }
      )
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.assessments.post_answer_grade')),
        { root: true }
      )
      dispatch('fetchAssessmentSession', { assessmentId, sessionId })
    } catch (err) {
      if (err?.response?.status === 401) {
        return
      }
      dispatch('notifications/add', notification('error', err), {
        root: true,
      })
    }
  },
  async fetchAssessmentComments({ commit, dispatch }, { assessmentId, studentId }) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/dashboard/assessments/${assessmentId}/students/${studentId}/comments`
      )
      commit('SET_CURRENT_LESSON_ASSESSMENT_COMMENTS', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async fetchPrerequisiteAssessments({ commit, dispatch }, courseId) {
    try {
      const res = await this.$axios.$get(
        `/api/v1/dashboard/courses/${courseId}/assessments?type=prerequisite&per_page=-1`
      )
      commit('SET_PREREQUISITE_ASSESSMENT', res.data)
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  newLiveComment({ commit }, comment) {
    commit('PUSH_COMMENT', comment)
  },
  async postCommentonAssessment({ dispatch }, { assessmentId, studentId, formData }) {
    try {
      let headers = {'Content-Type': 'multipart/form-data'}
      if (this.$echo.socketId()) {
        headers['X-Socket-ID'] = this.$echo.socketId()
      }
      await this.$axios.$post(
        `/api/v1/dashboard/assessments/${assessmentId}/students/${studentId}/comments`,
        formData,
        {headers}
      )
      dispatch('fetchAssessmentComments', { assessmentId, studentId })
    } catch (error) {
      if (!this.$axios.isCancel(error) && error?.response?.status !== 401) {
        dispatch('notifications/add', notification('error', error?.response), {
          root: true,
        })
      }
    }
  },
  async markAsManuallyGraded({dispatch},{ assessmentId, sessionId }){
    try{
      await this.$axios.post(
        `/api/v1/dashboard/assessments/${assessmentId}/sessions/${sessionId}/mark-as-manually-graded`,
      )
    }
    catch(error) {
      dispatch('notifications/add', notification('error', error?.response),{
        root: true
      })
    }

  }

}
