export const defaultState = () => ({
  notifications: [],
  unReadNotifications: [],
  totalNotifications: 0,
  totalUnReadNotifications: 0,
})
export const state = defaultState()

export const mutations = {
  RESET_SERVER_NOTIFICATIONS_STATE(state) {
    state.notification = {
      notifications: [],
      unReadNotifications: [],
      totalNotifications: 0,
      totalUnReadNotifications: 0,
    }
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  PUSH_NOTIFICATIONS(state, notifications) {
    state.notifications.push(...notifications)
  },
  PUSH_NOTIFICATION(state, notification) {
    state.notifications = [
      { id: notification.id, data: { ...notification } },
      ...state.notifications,
    ]
  },
  SET_TOTAL_NOTIFICATIONS(state, total) {
    state.totalNotifications = total
  },
  SET_UNREAD_NOTIFICATIONS(state, notifications) {
    state.unReadNotifications = notifications
  },
  PUSH_UNREAD_NOTIFICATION(state, notification) {
    state.unReadNotifications = [
      { id: notification.id, data: { ...notification } },
      ...state.unReadNotifications,
    ]
  },

  REMOVE_UNREAD_NOTIFICATION(state, notificationId) {
    const notificationToDelete = state.unReadNotifications.findIndex(
      (item) => item.id === notificationId
    )

    state.unReadNotifications.splice(notificationToDelete, 1)
    state.unReadNotifications = [...state.unReadNotifications]
  },

  SET_TOTAL_UNREAD_NOTIFICATIONS(state, total) {
    state.totalUnReadNotifications = total
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  resetNotificationsState({ commit }) {
    commit('RESET_SERVER_NOTIFICATIONS_STATE')
  },
  async setNotifications({ commit }, { per_page, page }) {
    const res = await this.$axios.get('/api/v1/notifications', { params: { per_page, page } })
    commit('SET_NOTIFICATIONS', res.data?.data)
    commit('SET_TOTAL_NOTIFICATIONS', res?.data?.meta?.pagination?.total)
  },

  async pushNotifications({ commit }, { per_page, page }) {
    const res = await this.$axios.get('/api/v1/notifications', { params: { per_page, page } })
    commit('PUSH_NOTIFICATIONS', res.data?.data)
    commit('SET_TOTAL_NOTIFICATIONS', res?.data?.meta?.pagination?.total)
  },

  pushNotification({ commit }, notification) {
    commit('PUSH_NOTIFICATION', notification)
  },

  async setUnReadNotifications({ commit }, { per_page, page }) {
    try {
      const res = await this.$axios.get('/api/v1/notifications/unread', {
        params: { per_page, page },
      })
      commit('SET_UNREAD_NOTIFICATIONS', res.data?.data)
      commit('SET_TOTAL_UNREAD_NOTIFICATIONS', res?.data?.meta?.pagination?.total)
    } catch (error) {
      if (error?.response?.status >= 400) {
        // do nothing
        console.log('unread notification unauthed')
      } else {
        console.log('unread error', error)
      }
    }
  },

  pushUnReadNotification({ commit }, notification) {
    commit('PUSH_UNREAD_NOTIFICATION', notification)
  },
  removeUnReadNotification({ commit }, notificationId) {
    commit('REMOVE_UNREAD_NOTIFICATION', notificationId)
  },
}
