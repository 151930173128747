import { notification } from '~/utils'

export const defaultState = () => ({
  user: {
    isVerified: undefined,
    isVerifying: false,
    unRegistered: undefined,
    info: null,
    token: null,
    isLoggedIn: false,
    source: '',
  },
  signupErrors: {}
})

export const state = defaultState()

export const mutations = {
  SET_SIGNUP_ERRORS(state, errors) {
    state.signupErrors = errors
  },
  RESET_SIGNUP_ERRORS(state) {
    state.signupErrors = {}
  },
  RESET_AUTHENTICATION_STATE(state) {
    state.user = {
      isVerified: undefined,
      isVerifying: false,
      unRegistered: undefined,
      info: null,
      token: null,
      isLoggedIn: false,
      source: '',
    }
    this.app.$sentry?.configureScope((scope) => scope.setUser(null))
  },
  SET_USER_INFO(state, userInfo) {
    state.user.info = userInfo
    this.app.$sentry.setUser({
      phone_number: userInfo.phone_number,
      name: userInfo.name,
      id: userInfo.id,
      ip_address: '{{auto}}',
    })
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  resetAuthenticationState({ commit }) {
    commit('RESET_AUTHENTICATION_STATE')
  },

  async logout({ dispatch }) {
    try {
      await dispatch('RESET_ALL', null, { root: true })
      console.log('unsubbing...')
      await this.$unSubPushNotifications()
      console.log('closing notificatgion socket...')
      await this.$closeNotificationsSocket(this.$auth, this.$echo)
      this.app.$sentry?.configureScope((scope) => scope.setUser(null))
      console.log('logging out')
      await this.$auth.logout()
      console.log('logged out')
    } catch (error) {
      console.log('error in logout')
      throw error
    }
  },

  async updateUserProfile({ dispatch, commit }, userInfo) {
    try {
      const res = await this.$axios.$post('/api/v1/users/update', userInfo, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      commit('SET_USER_INFO', res.data)
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.authentication.profile_update')),
        { root: true }
      )
    } catch (err) {
      dispatch('notifications/add', notification('error', err.response), {
        root: true,
      })
    }
  },

  async signup({ dispatch, commit }, userInfo) {
    commit('RESET_SIGNUP_ERRORS')
    try {
      await this.$axios.$post('/api/v1/auth/signup', {
        ...userInfo,
      })
      dispatch(
        'notifications/add',
        notification('success', this.$i18n.t('notifications.success')),
        {
          root: true,
        }
      )
    } catch (error) {

      dispatch('notifications/reportError', { response: "" }, { root: true })
      commit('SET_SIGNUP_ERRORS', error?.response?.data?.errors || {})
    }
  },
}
