/* eslint-disable */
console.log('setting zoho mansety');

// zoho salesiq brand: Mansety
window.$zoho=window.$zoho || {};$zoho.salesiq = $zoho.salesiq ||
{widgetcode: "siqf440afee962465d3eac919e54e63d1f9e9c4cb2c26269a8591f41a1cc60e7340", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);

window.$zoho.salesiq.ready = function () {
  window.$zoho.salesiq.chat.logo("https://new.unipuka.app/meta/unipuka.png");
}
