export default function ({ $echo, store, $auth }) {
  const channelName = `App.User.${$auth?.user?.data.id}`

  console.log('echo:', channelName)

  $echo.private(channelName).notification((notification) => {
    if (notification.type === 'new.comment') {
      const { course_id, lesson_id, assessment_id, session_id } = item.data
      store.dispatch('notifications/add', {
        type: 'success',
        messages: body,
        header: title,
        linkTo: `/dashboard/courses/${course_id}/lessons/${lesson_id}/assessments/${assessment_id}/followups/allQuestions/${session_id}`,
      })
    }

    store.dispatch('serverNotifications/pushNotification', notification)
    store.dispatch('serverNotifications/pushUnReadNotification', notification)
  })
}
