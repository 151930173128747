import { notification } from '~/utils'

export const defaultState = () => ({
  notifications: [],
  isLoading: false,
  totalUnread: 0,
})
export const state = defaultState()

let nextId = 1

export const mutations = {
  RESET_NOTIFICATIONS_STATE(state) {
    state.notification = { notifications: [], isLoading: false }
  },
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
    })
  },
  UNAUTHENTICATED(state) {
    state.notifications.push({
      type: 'error',
      messages: this.$i18n.t('authentication.unauthenticated'),
      id: nextId++,
    })
  },
  DELETE(state, notificationId) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationId
    )
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
  SET_TOTAL_UNREAD(state, total) {
    state.totalUnread = total
  },
}

export const actions = {
  setTotalUnread({ commit }, total) {
    commit('SET_TOTAL_UNREAD', total)
  },
  decrementTotalUnread({ commit, state }) {
    commit('SET_TOTAL_UNREAD', state.totalUnread > 0 ? state.totalUnread - 1 : 0)
  },
  resetNotificationsState({ commit }) {
    commit('RESET_NOTIFICATIONS_STATE')
  },
  add({ commit }, notification) {
    commit('PUSH', notification)
  },
  remove({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove)
  },
  unauthenticated({ commit }) {
    commit('UNAUTHENTICATED')
  },
  isLoading({ commit }, isLoading) {
    commit('SET_IS_LOADING', isLoading)
  },
  reportSuccess({ dispatch }, message) {
    dispatch('notifications/add', notification('success', message), {
      root: true,
    })
  },
  reportError({ dispatch }, error) {
    if (error?.response?.status === 401) {
      return
    }
    dispatch('notifications/add', notification('error', error?.response), {
      root: true,
    })
  },
}
