export const defaultState = () => ({
  lightboxImages: []
});

export const state = defaultState()

export const mutations = {
  SET_LIGHTBOX_IMAGES(state, images) {
    state.lightboxImages = images
  },
  HIDE_LIGHTBOX(state) {
    state.lightboxImages = []
  }
}
