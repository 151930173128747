import { notification } from '~/utils'

export const defaultState = () => ({
  teachers: [],
  supervisors: [],
  assistants: [],
  accountants: [],
  currentUser: null,
  totalTeachers: 500,
  totalSupervisors: 500,
  totalAssistants: 500,
  totalAccountants: 500,
})

export const state = defaultState();

export const mutations = {
  SET_TEACHERS(state, teachers) {
    state.teachers = teachers
  },
  SET_SUPERVISORS(state, supervisors) {
    state.supervisors = supervisors
  },
  SET_ASSISTANTS(state, assistants) {
    state.assistants = assistants
  },
  SET_ACCOUNTANTS(state, accountants) {
    state.accountants = accountants
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user
  },
  SET_TOTAL_TEACHERS(state, total) {
    state.totalTeachers = total
  },
  SET_TOTAL_SUPERVISORS(state, total) {
    state.totalSupervisors = total
  },
  SET_TOTAL_ASSISTANTS(state, total) {
    state.totalAssistants = total
  },
  SET_TOTAL_ACCOUNTANTS(state, total) {
    state.totalAccountants = total
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  async fetchTeachers({ commit, dispatch }, { per_page = 500, page = 1, q }) {
    try {
      if (q) {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'teacher',
          },
        })
        commit('SET_TEACHERS', res.data)
        commit('SET_TOTAL_TEACHERS', res.meta.pagination.total)
      } else {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'teacher',
          },
        })
        commit('SET_TEACHERS', res.data)
        commit('SET_TOTAL_TEACHERS', res.meta.pagination.total)
      }
    } catch (err) {
      if (this.$axios.isCancel(err)) {
      } else {
        console.warn('ERR', err)
        dispatch('notifications/add', notification('error', err.response), {
          root: true,
        })
      }
    }
  },
  async fetchSupervisors({ commit, dispatch }, { per_page = 500, page = 1, q }) {
    try {
      if (q) {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'supervisor',
          },
        })
        commit('SET_SUPERVISORS', res.data)
        commit('SET_TOTAL_SUPERVISORS', res.meta.pagination.total)
      } else {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'supervisor',
          },
        })
        commit('SET_SUPERVISORS', res.data)
        commit('SET_TOTAL_SUPERVISORS', res.meta.pagination.total)
      }
    } catch (err) {
      if (this.$axios.isCancel(err)) {
      } else {
        console.warn('ERR', err)
        dispatch('notifications/add', notification('error', err.response), {
          root: true,
        })
      }
    }
  },
  async fetchAssistants({ commit, dispatch }, { per_page = 500, page = 1, q }) {
    try {
      if (q) {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'assistant',
          },
        })
        commit('SET_ASSISTANTS', res.data)
        commit('SET_TOTAL_ASSISTANTS', res.meta.pagination.total)
      } else {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'assistant',
          },
        })
        commit('SET_ASSISTANTS', res.data)
        commit('SET_TOTAL_ASSISTANTS', res.meta.pagination.total)
      }
    } catch (err) {
      if (this.$axios.isCancel(err)) {
      } else {
        console.warn('ERR', err)
        dispatch('notifications/add', notification('error', err.response), {
          root: true,
        })
      }
    }
  },
  async fetchAccountants({ commit, dispatch }, { per_page = 500, page = 1, q }) {
    try {
      if (q) {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'accountant',
          },
        })
        commit('SET_ACCOUNTANTS', res.data)
        commit('SET_TOTAL_ACCOUNTANTS', res.meta.pagination.total)
      } else {
        const res = await this.$axios.$get('/api/v1/dashboard/users', {
          params: {
            per_page,
            page,
            type: 'accountant',
          },
        })
        commit('SET_ACCOUNTANTS', res.data)
        commit('SET_TOTAL_ACCOUNTANTS', res.meta.pagination.total)
      }
    } catch (err) {
      if (this.$axios.isCancel(err)) {
      } else {
        console.warn('ERR', err)
        dispatch('notifications/add', notification('error', err.response), {
          root: true,
        })
      }
    }
  },
}
