export const defaultState = () => ({
  studentRootCategory: {},
  childCategories: [],
})

export const state = defaultState()

export const mutations = {
  SET_STUDENT_ROOT_CATEGORY(state, studentRootCategory) {
    state.studentRootCategory = studentRootCategory
  },
  SET_STUDENT_CHILD_CATEGORIES(state, studentRootCategory) {
    state.childCategories = studentRootCategory
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
}

export const actions = {
  setStudentRootCategory({ commit }, studentRootCategory) {
    commit('SET_STUDENT_ROOT_CATEGORY', studentRootCategory)
  },
  setStudentChildCategories({ commit }, childCategories) {
    commit('SET_STUDENT_CHILD_CATEGORIES', childCategories)
  },
}
